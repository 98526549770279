import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material'
import Person from './../../assets/images/person.svg';
// import Bell from './../../assets/images/bell.svg';
// import Leap from './../../assets/images/leap.svg';
import ShoppingBag from './../../assets/images/shopping-bag.svg';
//import MyDestination from '../../assets/images/my-destination.svg';
import Car from './../../assets/images/car.svg';
import logout from './../../assets/images/logout.svg';
import Trips from './../../assets/images/Route.svg';
import NotebookIcon from "../../assets/images/Notebook.svg"
import LockIcon from "../../assets/images/Lock.svg"
import HelpIcon from "../../assets/images/Help.svg"
import CustomDrawer from '../mui/SwipeableDrawer'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { close } from '../../slice/drawer'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FC } from 'react';
import { logOutUser } from '../../services/logout';
import { enqueueSnackbar } from 'notistack';
import CalendarIcon from "../../assets/images/calendar.svg";
import {  groupIcon } from '../../assets/images';
import MarketPlaceIvite from '../../assets/images/marketplace_friends.svg'


interface ButtonTextProps {
  text: string;
  Icon: JSX.Element
}

const ButtonText: FC<ButtonTextProps> = ({ text, Icon }) => {
  return <Box className='center' justifyContent='space-between'>
    <Box display='flex' alignItems='center'>
      {Icon}
      <p className='ml-2 sub-text-14 text-700'>{text}</p>
    </Box>
    <NavigateNextIcon />
  </Box>
}

const Drawer = () => {

  const navigate = useNavigate();
  const { drawerReducer, userDetailsReducer: { basicDetails } } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleMenuClick = (url: string) => {
    navigate(url);
    dispatch(close());
  }

  const handleLogOut = async () => {
    try {
      await logOutUser();
    } catch (error) {
      console.log(error);
    }finally{
      localStorage.removeItem("token");
      enqueueSnackbar("Logout Successfully")
      navigate("/");
    }
  }

  const handleClose = () => {
    dispatch(close())
  }

  return (
    <div>
      <CustomDrawer
        anchor="bottom"
        isOpen={drawerReducer.open}
        onClose={handleClose}
      >
        <Box className="margin-x">
          <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/profile")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My profile"
                    Icon={<img width="17px" alt="profile" src={Person} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>

          {basicDetails.userPreference !== "FINDER" && basicDetails.userPreference !== 'MARKET_PLACE' && (
            <ListItem className="list-container" component="div" disablePadding>
              <ListItemButton
                onClick={() => handleMenuClick("/dashboard/vehicle-detail")}
              >
                <ListItemText
                  primary={
                    <ButtonText
                      text="My vehicle"
                      Icon={<img width="17px" alt="profile" src={Car} />}
                    />
                  }
                />
              </ListItemButton>
            </ListItem>
          )}

          {/* <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/my-destination")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My destinations"
                    Icon={
                      <img width="17px" alt="profile" src={MyDestination} />
                    }
                  />
                }
              />
            </ListItemButton>
          </ListItem> */}
              {basicDetails.userPreference !== 'MARKET_PLACE' && <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/upcoming")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My trips"
                    Icon={<img width="17px" alt="profile" src={Trips} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>}

          {basicDetails.userPreference !== 'MARKET_PLACE' &&
            <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/schedule")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My schedule"
                    Icon={<img width="20px" alt="profile" src={CalendarIcon} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>}

          {/* {basicDetails.userPreference !== "OFFER" && basicDetails.userPreference !== 'MARKET_PLACE' &&
          <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/invite-alerts")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My invite alerts"
                    Icon={<img width="20px" alt="profile" src={bell} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>} */}

          {basicDetails.userPreference !== "FINDER" && basicDetails.userPreference !== 'MARKET_PLACE' &&
            (<ListItem className="list-container" component="div" disablePadding>
              <ListItemButton
                onClick={() => handleMenuClick("/dashboard/group")}
              >
                <ListItemText
                  primary={
                    <ButtonText
                      text="My carpool group"
                      Icon={<img width="20px" alt="profile" src={groupIcon} />}
                    />
                  }
                />
              </ListItemButton>
            </ListItem>)}
          
          {/* <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/schedule-detail")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My invite alerts"
                    Icon={<img width="17px" alt="profile" src={Bell} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem> */}

          {basicDetails.userPreference !== 'MARKET_PLACE' && <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/market-place/invite")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="Invite friends to marketplace"
                    Icon={<img width="17px" alt="add-user" src={MarketPlaceIvite} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>}

          <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/market-place")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="Rewards marketplace"
                    Icon={<img width="17px" alt="profile" src={ShoppingBag} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>

          {/* <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => handleMenuClick("/dashboard/eco-saving")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="My eco savings"
                    Icon={<img width="17px" alt="profile" src={Leap} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem> */}

          <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => window.open(`${process.env.REACT_APP_HELP_URL}`, "_blank")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="Help center"
                    Icon={<img width="17px" alt="profile" src={HelpIcon} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>

          <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => window.open("https://drive.google.com/file/d/1H_ktE1jW7c-ztjrFqOlOarF10mlSoDdV/view", "_blank")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="Terms and conditions"
                    Icon={<img width="17px" alt="profile" src={NotebookIcon} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>

          <ListItem className="list-container" component="div" disablePadding>
            <ListItemButton
              onClick={() => window.open("https://drive.google.com/file/d/1ZsP8MWMiqhvKyPQ-lhqmY_VFbZI5Q-tu/view?usp=sharing", "_blank")}
            >
              <ListItemText
                primary={
                  <ButtonText
                    text="Privacy policy"
                    Icon={<img width="17px" alt="profile" src={LockIcon} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            className="list-container last-list-container"
            component="div"
            disablePadding
          >
            <ListItemButton onClick={handleLogOut}>
              <ListItemText
                primary={
                  <ButtonText
                    text="Log out"
                    Icon={<img width="17px" alt="profile" src={logout} />}
                  />
                }
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </CustomDrawer>
    </div>
  );
}

export default Drawer
