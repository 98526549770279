import "./style.scss";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, ListItem } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Header from "../../../components/header-1";
//import ScheduleInfo from '../../../components/schedule-info';
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import RightIconLight from "../../../assets/images/righ-arrow-light.svg";
import DriverCar from "../../../assets/images/driver-car.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
// import CheckBlackIcon from "../../../assets/images/checkmark_black.svg";
// import PendingIcon from "../../../assets/images/pending.svg";
// import CancelledIcon from "../../../assets/images/cancel.svg";
import Person from "../../../assets/images/person.svg";
import Schedule from "../../../assets/images/schedule.svg";
import Share from "../../../assets/images/share.svg";
import Leap from "../../../assets/images/leap.svg";
import Star from "../../../assets/images/star.svg";
import Car from "../../../assets/images/car.svg";
import GreenCar from "../../../assets/images/car_green.svg";
import GreenStearing from "../../../assets/images/stearing_wheel_green.svg"
import NoTripsCard from "../../../components/no-trips-card";
import logout from "../../../assets/images/logout.svg";
import { logOutUser } from "../../../services/logout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { getAllTripsUserPopulated } from "../../../services/trips";
import pathIcon from "../../../assets/images/path-icon.svg";
import loadClockGreen from "../../../assets/images/loadClockGreen.svg";
import cancelIcon from "../../../assets/images/cancel.svg";
import {
  updateAllTripsData,
  // updateCurrentPage,
  // updateTripStatus,
  TripInterface,
  setTripDetail,
  updateTripsHistory,
} from "../../../slice/trip";
import moment from "moment";
import { updateCatList, updateUserFavLocations } from "../../../slice/category";
import { getCategory, getFavLocationOfUser } from "../../../services/location";
import { updateFavLocationUtility } from "../../../slice/utility";
import LoadingScreen from "../../../components/loading-screen";
import { enqueueSnackbar } from "notistack";
import { ImageComponentOverlapping } from "../../../components/img-overlapping";
import { BackToWhatsAppButton } from "../../../components/back-to-whatsapp";

export interface GroupedTrips {
  [scheduleDate: string]: TripInterface[];
}

const UpcomingTrip = () => {
  let [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [currTab, setCurrTab] = useState(searchParams.get("passenger") ? "PASSENGER" : "DRIVER");
  const [selectedTrips1, setSelectedTrips1] = useState<GroupedTrips>({});
  const [pastTrips, setPastTrips] = useState<GroupedTrips>({});
  const navigate = useNavigate();
  const {
    userDetailsReducer,
    tripReducer: { currentPage, status, allTrips, tripsHistory },
    categoryReducer: { category },
  } = useSelector((state: RootState) => state);

  const { basicDetails, user } = userDetailsReducer;
  const dispatch = useDispatch<AppDispatch>();
  const [state, setState] = useState({
    addSchedule: {
      isOpen: false,
    },
  });

  // when loader is showing, then hide the scrollbar
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  // SORT the fetched trips to display
  useEffect(() => {
    let temp = [] as TripInterface[];
    let tripsArray = status === "UPCOMING" ? allTrips : tripsHistory;

    if (Array.isArray(tripsArray)) {
      temp = [
        ...[...tripsArray]
          .filter((trip) => {
            if (currTab === "DRIVER") {
              return trip._driver._id === user._id;
            } else {
              return trip._driver._id !== user._id;
            }
          })
          .sort((a: object, b: object) => {
            if (
              moment((a as { scheduleDate: string }).scheduleDate) >
              moment((b as { scheduleDate: string }).scheduleDate)
            ) {
              return 1;
            } else if (
              moment((a as { scheduleDate: string }).scheduleDate) <
              moment((b as { scheduleDate: string }).scheduleDate)
            ) {
              return -1;
            } else {
              return 0;
            }
          }),
      ];

      status === "UPCOMING"
        ? setSelectedTrips1(groupTripsByScheduleDate(temp))
        : setPastTrips(groupTripsByScheduleDate(temp));
    }

    //eslint-disable-next-line
  }, [allTrips, currentPage, status, currTab, tripsHistory]);

  function groupTripsByScheduleDate(trips: TripInterface[]) {
    const groupedTrips = {} as GroupedTrips;

    trips.forEach((trip) => {
      const scheduleDate = moment(trip.scheduleDate).format("ddd Do MMM");

      if (scheduleDate) {
        if (!groupedTrips[scheduleDate]) {
          groupedTrips[scheduleDate] = [];
        }

        groupedTrips[scheduleDate].push(trip);
      }
    });

    return groupedTrips;
  }

  const countTrip = (tab: "DRIVER" | "PASSENGER") => {
    if (tab === "DRIVER") {
      return (status === "UPCOMING" ? allTrips : tripsHistory).reduce(
        (count, tripCurrent) => {
          if (tripCurrent._driver._id === user._id) {
            return count + 1;
          } else {
            return count;
          }
        },
        0
      );
    } else {
      return (status === "UPCOMING" ? allTrips : tripsHistory).reduce(
        (count, tripCurrent) => {
          if (tripCurrent._driver._id !== user._id) {
            return count + 1;
          } else {
            return count;
          }
        },
        0
      );
    }
  };

  // const handleClickStatus = () => {
  //   const targetStatus = status === "UPCOMING" ? "COMPLETED" : "UPCOMING";

  //   dispatch(updateTripStatus(targetStatus));

  //   dispatch(updateCurrentPage(1));
  // };

  const handleAddSchedule = () => {
    setState((prev) => {
      return {
        ...prev,
        addSchedule: {
          ...prev.addSchedule,
          isOpen: !state.addSchedule.isOpen,
        },
      };
    });
  };

  const logoutUser = () => {
    logOutUser()
      .then(() => {
        enqueueSnackbar("Logout Successfully");

        localStorage.removeItem("token");

        navigate("/");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getData = async () => {
    try {
      const res1 = await getCategory();

      const res2 = await getFavLocationOfUser();

      const utilityFavLocList = res2.map((favLocation: any) => {
        const { _categoryId, _favoriteLocation } = favLocation;

        return {
          categoryId: _categoryId,
          locationId: _favoriteLocation,
          locationName: [{ lang: "EN", value: "" }],
          categoryName: [{ lang: "EN", value: "" }],
        };
      });

      dispatch(updateFavLocationUtility(utilityFavLocList));

      dispatch(updateCatList(res1));

      dispatch(updateUserFavLocations(res2));
    } catch (error: any) {
      console.log(error);
    }
  };

  // const getTripStatusIcon = (status: string) => {
  //   switch (status) {
  //     case "COMPLETED": {
  //       return CheckBlackIcon;
  //     }

  //     case "CONFIRMED": {
  //       return CheckBlackIcon;
  //     }

  //     case "EXPIRED": {
  //       return CancelledIcon;
  //     }

  //     case "PENDING": {
  //       return PendingIcon;
  //     }

  //     default: {
  //       return CancelledIcon;
  //     }
  //   }
  // };

  const getTripColorClass = (status: string) => {
    switch (status) {
      case "COMPLETED": {
        return "bg-green";
      }

      case "CONFIRMED": {
        return "bg-green";
      }

      case "PENDING": {
        return "gray-trip-status";
      }

      case "EXPIRED": {
        return "red-trip-status";
      }

      default: {
        return "red-trip-status";
      }
    }
  };

  const getCondition = () => {
    return status === "UPCOMING"
      ? allTrips.length === 0
      : tripsHistory.length === 0;
  };

  useEffect(() => {
    if (getCondition()) {
      setLoading(true);

      getAllTripsUserPopulated({ tripStatus: status, page: currentPage })
        .then((res) => {
          status === "UPCOMING"
            ? dispatch(updateAllTripsData(res))
            : dispatch(updateTripsHistory(res));

          getData().then(() => {
            if (basicDetails.userPreference === "BOTH" && !searchParams.get("passenger")) {
              setCurrTab("DRIVER");
            } else if (basicDetails.userPreference === "OFFER" && !searchParams.get("passenger")) {
              setCurrTab("DRIVER");
            } else {
              setCurrTab("PASSENGER");
            }
          });

          setLoading(false);
        })
        .catch(() => {
          enqueueSnackbar(
            "Unable to get trip data. Please try refreshing the page",
            { variant: "error" }
          );

          setLoading(false);

          if (basicDetails.userPreference === "BOTH") {
            setCurrTab("DRIVER");
          } else if (basicDetails.userPreference === "OFFER") {
            setCurrTab("DRIVER");
          } else {
            setCurrTab("PASSENGER");
          }
        });
    } else {
      setLoading(false);

      if (basicDetails.userPreference === "BOTH" && !searchParams.get("passenger")) {
        setCurrTab("DRIVER");
      } else if (basicDetails.userPreference === "OFFER" && !searchParams.get("passenger")) {
        setCurrTab("DRIVER");
      } else {
        setCurrTab("PASSENGER");
      }
    }

    //eslint-disable-next-line
  }, [currentPage, status, basicDetails.userPreference]);

  //disbale from going back from this page
  // useEffect(()=>{
  //     window.history.pushState(null, "", window.location.href);
  //     window.onpopstate = handler;
  //     return ()=>{
  //         window.removeEventListener('popstate',handler)
  //     }
  // },[])
  

  // get category name as per the display format
  const getCategoryName = (locationId: string, tripType: string) => {
    for (let cat of category) {
      for (let loc of cat.locations) {
        if (loc._id.toString() === locationId) {
          return `${cat.locationName[0].value}-${tripType}`;
        }
      }
    }

    return `N/A-${tripType}`;
  };

  return (
    <div id="upcoming-detail">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Header
            rightIcon={basicDetails.photo}
            handleClickRight={handleAddSchedule}
          />

          <BackToWhatsAppButton />

          <Box className="padding-x rounded-top-green">
            <div className="sub-header">
              <div className="sub-header-icon-container">
                <img alt="signature" src={pathIcon} />
              </div>
              <p className="heading sub-text-new">My trips</p>
            </div>
          </Box>

          <Box
            className="p-1 upcoming-trips-container"
            style={{ background: "#fff" }}
          >
            <Box
              className="pill-bar-wrapper gap-12 items-center"
              marginBottom={"24px"}
              justifyContent="space-between"
            >
              <div className="d-flex gap-12">
                {(basicDetails.userPreference === "BOTH" ||
                  basicDetails.userPreference === "OFFER") && (
                  <p
                    className={`${
                      currTab === "DRIVER" ? "pill pill-active" : "pill"
                    }`}
                    onClick={() => setCurrTab("DRIVER")}
                  >
                    I'm driving ({countTrip("DRIVER")})
                  </p>
                )}

                {(basicDetails.userPreference === "BOTH" ||
                  basicDetails.userPreference === "FINDER") && (
                  <p
                    className={`${
                      currTab === "PASSENGER" ? "pill pill-active" : "pill"
                    }`}
                    onClick={() => setCurrTab("PASSENGER")}
                  >
                    I'm a passenger ({countTrip("PASSENGER")})
                  </p>
                )}
              </div>

              {/* <p
                className="sub-text-14 primary-color click show-trips-text"
                onClick={handleClickStatus}
              >
                {status === "UPCOMING" ? " View history " : "View upcoming"}
              </p> */}
            </Box>

            {Object.keys(
              status === "UPCOMING" ? selectedTrips1 : pastTrips
            ).map((it: string, key) => (
              <div key={key}>
                <p className="section-head">{it}</p>

                {(status === "UPCOMING" ? selectedTrips1 : pastTrips)[it].map(
                  (trip, tripKey) => (
                    <div key={tripKey}>
                      <Box
                        display="flex"
                        className="click margin-y"
                        onClick={() => {
                          dispatch(setTripDetail(trip));
                          navigate("/dashboard/trip-detail");
                        }}
                      >
                        {currTab !== "DRIVER" ? (
                          <>
                            {
                              trip.tripStatus === "CANCELLED" ? (
                                <div
                                  className="image-thumbnail rounded-full bordered-2 shadow-light d-flex justify-center items-center bg-gray"
                                >
                                  <img src={trip.tripStatus === 'CANCELLED' ? cancelIcon : loadClockGreen} alt="no user booked icon" />
                                </div>
                              ) : (
                                <img
                                  alt="profile"
                                  className="image-thumbnail rounded-full bordered-2 shadow-light"
                                  src={trip?._driver?.photo ?? DefaultProfile}
                                />
                              )
                            }
                          </>
                        ) : (
                          <>
                            {
                              (trip._user.length > 0 && trip.tripStatus !== 'CANCELLED') ? (
                                <div style={{ minWidth: "50px", paddingTop: "25px" }}>
                                  <ImageComponentOverlapping
                                    image={[
                                      ...(trip?._user?.map((it) => it.photo) || []),
                                      ...(trip?.invitedUsers?.map((it) => it.photo) ||
                                        []),
                                    ]}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="image-thumbnail rounded-full bordered-2 shadow-light d-flex justify-center items-center bg-gray"
                                >
                                  <img src={((trip.tripStatus === 'CANCELLED') || (trip.tripStatus === 'EXPIRED')) ? cancelIcon : loadClockGreen} alt="no user booked icon" />
                                </div>
                              )
                            }
                          </>
                        )}

                        <Box marginLeft="18px" className="info">
                          {
                            currTab === "PASSENGER" && (
                              <p className="text-700 sub-text-14">
                                {
                                  trip._driver?.firstName[0].value + " " + trip._driver?.lastName[0].value
                                }
                              </p>
                            )
                          }

                          <Box display="flex" marginY={"4px"}>
                            {
                              // if the current tab is passenger show car icon else show stearing
                              currTab === "PASSENGER" ? (
                                <div className="d-flex gap-1">
                                  <div className="d-flex">
                                    <img alt="car-driver" src={DriverCar} />
                                  </div>

                                  <div
                                    className={`
                                    ${getTripColorClass(
                                      ((trip.tripStatus !== "CANCELLED") && (trip._user.length !== 0)) ? 
                                      "CONFIRMED" : 
                                      trip.tripStatus === "CANCELLED" ? "CANCELLED" : "PENDING"
                                    )} 
                                    d-flex item-center p-02 px-05 rounded-16
                                    `}
                                  >
                                    {/* {
                                      status === "UPCOMING" && (
                                        <div className="mx-1 d-flex">
                                          <img
                                            alt="car-driver "
                                            src={getTripStatusIcon(status === "UPCOMING" ? "CONFIRMED" : trip.tripStatus)}
                                          />
                                        </div>
                                      )
                                    } */}

                                    <div className={`text-500 sub-text ${status === "UPCOMING" && 'text-700'}`}>
                                      {
                                        status === "UPCOMING" ? "Confirmed" : trip.tripStatus.charAt(0) +
                                        trip.tripStatus.slice(1).toLowerCase()
                                      }
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex gap-1">
                                  <div className="d-flex mr-1">
                                    <img
                                      alt="car-stearing"
                                      src={CarStearingIcon}
                                    />
                                  </div>

                                  <div
                                    className={`
                                    ${getTripColorClass(
                                      ((trip.tripStatus !== "CANCELLED") && (trip._user.length !== 0)) ? 
                                      "CONFIRMED" : 
                                      ((trip.tripStatus === "CANCELLED") || (trip.tripStatus === "EXPIRED")) ? "CANCELLED" : "PENDING"
                                    )} 
                                    d-flex item-center p-02 px-05 rounded-16
                                    `}
                                  >
                                    {/* {
                                      trip._user.length > 0 && trip.tripStatus !== "CANCELLED" && (
                                        <div className="mx-1 d-flex">
                                          <img
                                            alt="car-driver "
                                            src={getTripStatusIcon(trip._user.length !== 0 ? "CONFIRMED" : trip.tripStatus)}
                                          />
                                        </div>
                                      )
                                    } */}

                                    <div className={`sub-text ${(trip._user.length > 0 && trip.tripStatus !== "CANCELLED") ? 'text-700' : 'trip-text-light-gray'}`}>
                                      {
                                        trip.tripStatus === "CANCELLED" ? "Cancelled" : 
                                        trip.tripStatus === "EXPIRED" ? "Expired" :
                                        (
                                          <>
                                            {
                                              trip._user.length + '/' + trip.totalSeats
                                            } seats booked
                                          </>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </Box>

                          <Box>
                            <p className="sub-text text-500 text-gray mb-1 trip-text-light-gray">
                              {trip.destinationLocationId === undefined
                                ? getCategoryName(
                                    trip.pickUpLocationId,
                                    "pickup, "
                                  )
                                : getCategoryName(
                                    trip.destinationLocationId,
                                    "dropoff, "
                                  )}

                              {moment(trip.scheduleDate).format("HH:mm")}
                            </p>
                          </Box>
                        </Box>

                        <Box marginLeft={"auto"}>
                          <img src={RightIconLight} alt={"right arrow"} />
                        </Box>
                      </Box>
                    </div>
                  )
                )}
              </div>
            ))}

            {Object.keys(status === "UPCOMING" ? selectedTrips1 : pastTrips)
              .length === 0 && (
                <Box>
                <NoTripsCard
                  logo={currTab.toLowerCase() === 'driver' ? GreenStearing: GreenCar}
                  heading={currTab.toLowerCase() === 'driver' ? "You're riding solo" : "No seat bookings"}
                  description={ currTab.toLowerCase() === 'driver' ? "It looks like you are riding solo. Offer a carpool today." :
                   "You don't currently have any seat bookings in carpools."}
                />
              </Box>
            )}
          </Box>

          <CustomDrawer
            anchor="bottom"
            isOpen={state.addSchedule.isOpen}
            onClose={handleAddSchedule}
          >
            <Box className="margin-x">
              <ListItem
                className="list-container"
                component="div"
                disablePadding
              >
                <ListItemButton onClick={() => navigate("/dashboard/profile")}>
                  <ListItemText
                    primary={
                      <ButtonText
                        text="My profile"
                        Icon={<img width="17px" alt="profile" src={Person} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                className="list-container"
                component="div"
                disablePadding
              >
                <ListItemButton
                  onClick={() => navigate("/dashboard/schedule-detail")}
                >
                  <ListItemText
                    primary={
                      <ButtonText
                        text="My driving schedule"
                        Icon={<img width="17px" alt="profile" src={Schedule} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>
              {/* <ListItem
                className="list-container"
                component="div"
                disablePadding
              >
                <ListItemButton
                  onClick={() => navigate("/dashboard/invite-alerts")}
                >
                  <ListItemText
                    primary={
                      <ButtonText
                        text="My invite alerts"
                        Icon={<img width="17px" alt="profile" src={bell} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem> */}
              <ListItem
                className="list-container"
                component="div"
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    primary={
                      <ButtonText
                        text="Refer & earn"
                        Icon={<img width="17px" alt="profile" src={Share} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                className="list-container"
                component="div"
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    primary={
                      <ButtonText
                        text="My Eco rewards"
                        Icon={<img width="17px" alt="profile" src={Leap} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                className="list-container"
                component="div"
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    primary={
                      <ButtonText
                        text="My Eco savings"
                        Icon={<img width="17px" alt="profile" src={Star} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>

              {basicDetails.userPreference !== "FINDER" && (
                <ListItem
                  className="list-container"
                  component="div"
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => navigate("/dashboard/vehicle-detail")}
                  >
                    <ListItemText
                      primary={
                        <ButtonText
                          text="My vehicle"
                          Icon={<img width="17px" alt="profile" src={Car} />}
                        />
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem
                className="list-container last-list-container"
                component="div"
                disablePadding
              >
                <ListItemButton onClick={logoutUser}>
                  <ListItemText
                    primary={
                      <ButtonText
                        text="Logout"
                        Icon={<img width="17px" alt="profile" src={logout} />}
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Box>
          </CustomDrawer>
        </>
      )}
    </div>
  );
};

interface ButtonTextProps {
  text: string;
  Icon: JSX.Element;
}

const ButtonText: FC<ButtonTextProps> = ({ text, Icon }) => {
  return (
    <Box className="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {Icon}

        <p className="ml-2 sub-text-14 text-700">{text}</p>
      </Box>
      <NavigateNextIcon />
    </Box>
  );
};

export default UpcomingTrip;
