import { useEffect, useState } from "react";
import DefaultUserIcon from "../../assets/images/defaultUser.svg";
import Calendar from "../../assets/images/calendar.svg";
import Header from "../../components/header";
import { Box } from "@mui/material";
import './style.scss';
import CustomDatePicker from "../../components/time-picker";
import { createSchedule, updateSchedule } from "../../services/signup";
import LoadingScreen from "../../components/loading-screen";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { getFavLocationOfUser } from "../../services/location";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { updateBasicDetails } from "../../slice/login";
import CustomFixedButton from "../custom-fixed-button";
import { BackToWhatsAppButton } from "../back-to-whatsapp";

const MySchedule = ({page}) => {
  const [loading, setLoading] = useState(false);

  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer)
  const signUpDetails = useSelector((state: RootState) => state.signUpDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    carPoolPreference: userDetailsReducer.basicDetails.userPreference || 'BOTH',
    selectedDays: [],
    arriveBy: "09:00",
    departBy: "17:30"
  });

  useEffect(() => {
    page !== 'signup' && getScheduleData();
    // eslint-disable-next-line
  }, []);

  const updateSelectedDays = (day) => {
    if (state.selectedDays.includes(day) === true) {
      setState(prev => {
        return {
          ...prev,
          selectedDays: state.selectedDays.filter(item => item !== day)
        }
      });
    } else {
      setState(prev => {
        return {
          ...prev,
          selectedDays: [...state.selectedDays, day]
        }
      });
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const schedules = state.selectedDays.map(day => {
        return {
          day,
          dropOff: state.arriveBy,
          pickUp: state.departBy
        }
      });

      const _draft = localStorage.getItem("draftId");

      const payload = {
        // preference: state.carPoolPreference,
        schedules,
      };

      if (payload.schedules.length <= 0) {
        throw new Error('Must select atleast one day')
      }
      page === 'signup' && (payload['_draft'] = _draft);

      page === 'signup' ? await createSchedule({
        ...payload
      }) : await updateSchedule({
        ...payload
      }) ;
      
      page !== 'signup' && enqueueSnackbar("Updated successfully", {
        variant: "success"
      });
      dispatch(updateBasicDetails({userPreference: state.carPoolPreference}))

      page === 'signup' ? navigate("/signup/vehicle-detail") : navigate("/dashboard/home");

      // redirect to vehicle detail page if user is driver or both else redirect to summary page
      // if(page === 'signup' && 
      // ((state.carPoolPreference === "OFFER") || (state.carPoolPreference === "BOTH"))
      // ) {
      //   navigate("/signup/vehicle-detail");
      // } else if(
      //   page === 'signup' && 
      // (state.carPoolPreference === "FINDER")
      // ) {
      //   navigate("/signup/summary");
      // } else {
      //   navigate("/dashboard/home");
      // }


      setLoading(false);
    } catch (error) {
      enqueueSnackbar( error.message, {
        variant: "error"
      });
      console.log(error);
      setLoading(false);
    }
    return;
  }

  const getScheduleData = async () => {
    try {
      const location = (await getFavLocationOfUser())[0];
      const selectedDays = [];
      let arriveBy = "";
      let departBy = "";
      location.userSchedule.forEach((item: {day: string, pickUp: string, dropOff: string}) => {
        selectedDays.push(item.day.toLowerCase());
        arriveBy= item.dropOff;
        departBy = item.pickUp;
      });

      setState(prev => {
        return {
          ...prev,
          arriveBy,
          departBy,
          selectedDays: [...selectedDays]
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  return (loading ? (
    <LoadingScreen />
  ) :
    (<Box className="pb-150">
      <Header
        canGoBack={true}
        rightIcon={<img alt='profile' className='header-img-icon' src={
          page === 'signup' ? (signUpDetails.basicDetails.photoPath as string) ?? DefaultUserIcon : userDetailsReducer.basicDetails.photo
        } />}
        signUp={page === 'signup' ? true : false}
      />

      {page !== 'signup' && (<BackToWhatsAppButton />)}

      <Box className="padding-x rounded-top-green">
        <div className="sub-header">
          <div className="sub-header-icon-container">
            <img alt="person" src={Calendar} />
          </div>

          <b className='title'>My Schedule</b>
        </div>
      </Box>
      <Box className="p-1">
        {/* <CarpoolingPreferences state={state} updateState={setState} /> */}
        <DaysOfCarpool state={state} updateSelectedDays={updateSelectedDays} />
        <CarpoolTime defaultTime={state.arriveBy} title="Arrive by" updateState={(e) => {
          setState(prev => {
            return {
              ...prev,
              // arriveBy: e.format("HH:MM")
              // fix additional two minutes added automatically
              arriveBy: `${e.hour().toString().length === 1 ? `0${e.hour()}` : e.hour()}:${e.minute().toString().length === 1 ? `0${e.minute()}` : e.minute()}`
            }
          })
        }} />
        <CarpoolTime defaultTime={state.departBy} title="Depart after" updateState={(e) => {
          setState(prev => {
            return {
              ...prev,
              // departBy: e.format("HH:MM")
              departBy: `${e.hour().toString().length === 1 ? `0${e.hour()}` : e.hour()}:${e.minute().toString().length === 1 ? `0${e.minute()}` : e.minute()}`
            }
          })
        }} />
        <Description />
      </Box>
        <Next loading={loading} handleSubmit={handleSubmit} page={page} />

    </Box>)
  );
}

const Next = ({ loading, handleSubmit, page }) => {
  return (
    // <Box className="my-schedule-next-button-container">
    //   <Box className="button-width mx-auto d-flex justify-center items-center">
    //   <Button
    //     className="next-btn custom-btn px-15rem btn-width"
    //     disabled={loading}
    //     type={"submit"}
    //     onClick={handleSubmit}
    //     fullWidth
    //   >
    //     { page === 'signup' ? 'Next' : 'Update' }
    //   </Button>
    //   </Box>
    // </Box>
    <CustomFixedButton disable={loading} handleSubmit={handleSubmit} text={ page === 'signup' ? 'Next' : 'Update' }></CustomFixedButton>
  )
}

// const CarpoolingPreferences = ({ state, updateState }) => {
//   return (
//     <div className="d-flex flex-column">
//       <b className="carpool-title">I plan to use EcoPooling to carpool as a: </b>

//     <FormControl>
//       <RadioGroup
//         name={"carPoolPreference"}
//         onChange={(e) => {
//           updateState(prev => {
//             return { ...prev, carPoolPreference: e.target.value }
//           });
//         }}
//         value={state.carPoolPreference}
//       >
//         <FormControlLabel
//           className="sub-text radio-btn"
//           value="BOTH"
//           control={<Radio />}
//           label="Driver or passenger"
//         />

//         <FormControlLabel
//           className="sub-text radio-btn"
//           value="OFFER"
//           control={<Radio />}
//           label="Driver"
//         />

//         <FormControlLabel
//           className="sub-text radio-btn"
//           value="FINDER"
//           control={<Radio />}
//           label="Passenger"
//         />
//       </RadioGroup>
//     </FormControl>
//     </div>
//   )
// }

const Description = () => {
  return (
    <p className='description'>
      {/* You'll be able to choose custom dates and times when offering or booking a seat in a carpool,
      we'll just use the above schedule as your defaults. */}
      This schedule will be used to match you with other commuters in your community on your route. Don't worry; you can
      easily update it each week with just a few clicks if your plans change.
    </p>
  );
};

const CarpoolTime = ({ title, updateState, defaultTime }) => {
  return (
    <div>
      <h4 className="mb-1 font-14">{title}</h4>
      <div className="w-50">
        <CustomDatePicker timeDefault={defaultTime} disbled={false} handleChange={updateState}></CustomDatePicker>
      </div>
    </div>
  )
}

const DaysOfCarpool = ({ state, updateSelectedDays }) => {

  const availableDays = [{
    key: 'Mon',
    value: 'monday'
  }, {
    key: 'Tue',
    value: 'tuesday'
  }, {
    key: 'Wed',
    value: 'wednesday'
  }, {
    key: 'Thu',
    value: 'thursday'
  }, {
    key: 'Fri',
    value: 'friday'
  }];

  const getHeaderText = () => {
    const preference = state?.carPoolPreference?.toUpperCase();
    switch (preference) {
      case 'BOTH':
        return 'Select the days you can share lifts'
      case 'FINDER':
        return 'Select the days you need a lift'
      case 'OFFER':
        return 'Select the days you can offer lifts'
      default:
      return 'Select the days you can share lifts'
    }
  }

  return (<>
    <div className="mb-5">
      <div className="d-flex align-items-center">
        <h3 className="font-14">{getHeaderText()}</h3>
      </div>
      <div className="days-container">
        {
          availableDays.map(day => {
            return (
              state.selectedDays.includes(day.value) === true ?
                <div className="selected-day" key={day.key} onClick={e => updateSelectedDays(day.value)}><b>{day.key}</b></div> :
                <div className="unselected-day" key={day.key} onClick={e => updateSelectedDays(day.value)}>{day.key}</div>
            )
          })
        }
      </div>
    </div>
  </>
  )
}

export default MySchedule;