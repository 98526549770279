import "./style.scss";
import { Box, Button } from "@mui/material";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import LocationUpIcon from "../../../assets/images/location-up.svg";
import Leaf from "../../../assets/images/leaf.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import CarDrivingIcon from "../../../assets/images/driver-car.svg";
import ConfirmIcon from "../../../assets/images/checked.svg";
import PendingIcon from "../../../assets/images/pending.svg";
import CancelledIcon from "../../../assets/images/cancel.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  capitalizeFirstLetter,
  getName,
  getOrganisationLogo,
  getOrganizationName,
  showMapDuration,
  // showMapDuration,
} from "../../../utilities/helper";
import { enqueueSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  cancelTripStatus,
  getSingleTripDetail,
  getTripDuration,
  getTripReceipt,
} from "../../../services/trips";
import {
  TripInterface,
  setTripDetail,
} from "../../../slice/trip";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "../../../components/loading-screen";
import { ImageComponentOverlapping } from "../../../components/img-overlapping";
import { getCategory, getFavLocationOfUser } from "../../../services/location";
import { updateCatList, updateUserFavLocations } from "../../../slice/category";
import { updateFavLocationUtility } from "../../../slice/utility";
// import userVerified from "../../../assets/images/user-verified.svg";
// import userNotVerified from "../../../assets/images/user-not-verified.svg";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import { cancelInviteStatus } from "../../../services/invites";
import { BasicDetails } from "../../../slice/login";
import UserProfile from "../../../components/user-profile";
import { receiptIcon } from "../../../assets/images";

const TripDetail = () => {
  const [tripMeta, setTripMeta] = useState<{
    userDuration: string;
    totalTime: string;
    driverPassengerTime: string;
  }>({ userDuration: "N/A", totalTime: "N/A", driverPassengerTime: "N/A" });
  const [redirectLoading, setRedirectLoading] = useState(true);
  const [cancelTripDrawer, setCancelTripDrawer] = useState(false);
  const [userType, setUserType] = useState("");
  const [userDetail, setUserDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails;
    locationName: string;
    organisationLogo: string
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
    locationName: "",
    organisationLogo: ""
  });

  const navigate = useNavigate();

  const {
    userDetailsReducer: { basicDetails, user, 
      address: userAddress 
    },
    categoryReducer,
    tripReducer: { tripDetail: trip, status },
    redirectDataReducer: { decodedData },
  } = useSelector((state: RootState) => state);
  const { inviteError } = useSelector(
    (state: RootState) => state.inviteReducer
  );

  const [searchParams] = useSearchParams();

  const getData = async () => {
    try {
      const res1 = await getCategory();
      const res2 = await getFavLocationOfUser();

      const utilityFavLocList = res2.map((favLocation: any) => {
        const { _categoryId, _favoriteLocation } = favLocation;
        return {
          categoryId: _categoryId,
          locationId: _favoriteLocation,
          locationName: [{ lang: "EN", value: "" }],
          categoryName: [{ lang: "EN", value: "" }],
        };
      });
      dispatch(updateFavLocationUtility(utilityFavLocList));
      dispatch(updateCatList(res1));
      dispatch(updateUserFavLocations(res2));
    } catch (error: any) {
      console.log(error);
    }
  };

  const getCategoryByLocationId = (id: string) => {
    let locationName = "";
    for (let currCategory of categoryReducer.category) {
      for (let currLocation of currCategory.locations) {
        if (currLocation._id === id) {
          locationName = currLocation.locationName[0].value;
          return locationName;
        }
      }
    }
    return "N/A";
  };

  // get organisation name
  const getOrgNameByLocationId = (id: string, userDetailObj: object) => {
    let locationName = "";
    for (let currCategory of categoryReducer.category) {
      for (let currLocation of currCategory.locations) {
        if (currLocation._id === id) {
          locationName = getOrganizationName(currLocation, userDetailObj);
          return locationName;
        }
      }
    }
    return "N/A";
  };

  // get organisation logo
  const getOrgLogoByLocationId = (id: string, userDetailObj: object) => {
    let locationName = "";
    for (let currCategory of categoryReducer.category) {
      for (let currLocation of currCategory.locations) {
        if (currLocation._id === id) {
          locationName = getOrganisationLogo(currLocation, userDetailObj);
          return locationName;
        }
      }
    }
    return "N/A";
  };

  const dispatch = useDispatch();

  // fetch trip details data for this trip
  useEffect(() => {
    if (searchParams.get("redirect")) {
      // fetch data for all the tripIds, if tripIds is not found, then redirect to profile page with error
      if (
        decodedData?.tripIds === undefined ||
        decodedData?.tripIds?.length === 0
      ) {
        navigate("/");
      } else {
        setRedirectLoading(true);

        getSingleTripDetail(decodedData?.tripIds[0], undefined)
        .then((response) => {
          getData().then(() => {
            dispatch(setTripDetail(response.data[0]));

            setRedirectLoading(false);
          }).catch(() => {
            enqueueSnackbar("Unable to fetch trip details", { variant: "error" });

            navigate("/");
          })
        })
        .catch(() => {
          enqueueSnackbar("Unable to fetch trip details", { variant: "error" });
  
          navigate("/");
        });
      }
    } else {
      // if user is coming directly to this page, then navigate to homepage
      if (Object.keys(trip).length === 0) {
        navigate("/");

        return
      }

      getSingleTripDetail(trip._id, trip.tripStatus)
        .then((response) => {
          dispatch(setTripDetail(response.data[0]));
          getData().then(() => {
            dispatch(setTripDetail(response.data[0]));
            setRedirectLoading(false);
          }).catch(() => {
            enqueueSnackbar("Unable to fetch trip details", { variant: "error" });

            navigate("/");
          })
  
          setRedirectLoading(false);
        })
        .catch(() => {
          enqueueSnackbar("Unable to fetch trip details", { variant: "error" });
  
          navigate("/");
        });
    }

    // eslint-disable-next-line
  }, []);

  // // fetch trip data in case of redirect from whatsApp
  // useEffect(() => {
  //   if (searchParams.get("redirect")) {
  //     // fetch data for all the tripIds, if tripIds is not found, then redirect to profile page with error
  //     if (
  //       decodedData?.tripIds === undefined ||
  //       decodedData?.tripIds?.length === 0
  //     ) {
  //       navigate("/");
  //     } else {
  //       // set loading to true
  //       // setRedirectLoading(true);
  //       // getAllTripsWithPopulatedDetails({ tripStatus: "ALL", page: 1 }).then(
  //       //   (trips: { data: TripInterface[] }) => {
  //       //     dispatch(updateTripsData(trips));
  //       //     getData().then((val: any) => {
  //       //       trips.data.forEach((it) => {
  //       //         if (
  //       //           decodedData !== undefined &&
  //       //           decodedData.tripIds !== undefined &&
  //       //           decodedData.tripIds.length >= 1
  //       //         ) {
  //       //           if (it._id === decodedData.tripIds[0]) {
  //       //             dispatch(setTripDetail(it));
  //       //             setRedirectLoading(false);
  //       //           }
  //       //         }
  //       //       });
  //       //     });
  //       //   }
  //       // );
  //     }
  //   } else {
  //     if (Object.keys(trip).length === 0) {
  //       navigate("/");
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    // show the error message if request fails
    if (inviteError) {
      enqueueSnackbar(inviteError);
    }
  }, [inviteError]);

  // get the passenger to destination duration
  useEffect(() => {
    if (Object.keys(trip).length > 0) {
      if(trip.tripStatus === "CONFIRMED" && trip.isBeta !== true) {
        getAllCordinates(trip);
      }

      getTripMeta(trip._id);
    }
    //eslint-disable-next-line
  }, [trip]);

  const getTripMeta = async (tripId: string) => {
    try {
      const tripMeta = await getTripDuration(tripId);

      const data = await tripMeta.data;

      setTripMeta(data);
    } catch (error) {
      setTripMeta({
        userDuration: "N/A",
        totalTime: "N/A",
        driverPassengerTime: "N/A",
      });
    }
  };

  const initMap = (tripCordinate: { lat: number; lng: number }[]) => {
    var map = new google.maps.Map(
      (document as any).getElementById(
        "google-map-trip-detail"
      ) as HTMLDivElement,
      {
        center: tripCordinate[0],
        zoom: 8,
      }
    );

    // Create a new DirectionsService object
    var directionsService = new google.maps.DirectionsService();

    // Create a new DirectionsRenderer object
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
    });

    // Define the start and end locations
    var start = new google.maps.LatLng(
      tripCordinate[0].lat,
      tripCordinate[0].lng
    );

    var end = new google.maps.LatLng(
      tripCordinate[tripCordinate.length - 1].lat,
      tripCordinate[tripCordinate.length - 1].lng
    );

    // Configure the DirectionsRequest object
    var request = {
      origin: start,
      destination: end,
      waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
        location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
        stopover: true,
      })),

      travelMode: google.maps.TravelMode.DRIVING,
    };

    // Call the DirectionsService to get the route
    directionsService.route(request, function (result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        // Display the route on the map
        directionsRenderer.setDirections(result);

        // get the directions response of the route
        let directionsResult = directionsRenderer.getDirections()!;

        // loop through each leg and show the duration for each leg in info window
        let prevDistance = 0;
        for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
          prevDistance = showMapDuration(
            directionsResult,
            map,
            i,
            prevDistance
          );
        }
      }
    });
  };

  //Open Route on Map
  const getAllCordinates = (trip: TripInterface) => {
    const arr: Array<{ lat: number; lng: number }> = [];

    // trip start location
    arr.push({
      lat: parseFloat(String(trip.pickUp.geo[0])),
      lng: parseFloat(String(trip.pickUp.geo[1])),
    });

    if (user._id === trip._driver._id) {
      trip._user.forEach((it) => {
        arr.push({
          lat: parseFloat(String(it.address.geo[0])),
          lng: parseFloat(String(it.address.geo[1])),
        });
      });
    } else {
      arr.push({
        lat: parseFloat(String(userAddress.geo[0])),
        lng: parseFloat(String(userAddress.geo[1])),
      });
    }

    // trip end location
    arr.push({
      lat: parseFloat(String(trip.destination.geo[0])),
      lng: parseFloat(String(trip.destination.geo[1])),
    });

    initMap(arr);
  };

  const getTripStatusIcon = (status: string) => {
    switch (status) {
      case "COMPLETED": {
        return ConfirmIcon;
      }
      case "CONFIRMED": {
        return ConfirmIcon;
      }
      case "PENDING": {
        return PendingIcon;
      }
      default: {
        return CancelledIcon;
      }
    }
  };

  const getTripColorClass = (status: string) => {
    switch (status) {
      case "COMPLETED": {
        return "bg-green";
      }
      case "CONFIRMED": {
        return "bg-green";
      }
      case "PENDING": {
        return "gray-trip-status";
      }
      default: {
        return "red-trip-status";
      }
    }
  };

  const getTotalTripPrice = () => {
    let price = 0;

    price = trip._user.reduce((totalPrice, currUser) => {
      totalPrice = totalPrice + currUser.fuelPrice;
      return totalPrice;
    }, 0);

    return price.toFixed(2);
  };

  const getTotalTripPriceForUser = () => {
    let price = 0;

    trip._user.forEach((it) => {
      if (it._id === user._id) {
        price = it.totalPrice;
      }
    });

    return price.toFixed(2);
  };

  // trip cancel button handler
  const cancelButtonHandler = async () => {
    setRedirectLoading(true);

    try {
      // make a request to the api to update the status of the trip or invite
      let state = {} as {
        cancellationReason: string;
        locationName: string;
        driverName: string;
        passengerName?: string;
        from: string;
        to: string;
        date: string;
        price: number[];
        whatsAppNumber: string[];
        pickUpTime: string[];
        dropOffTime: string[];
        jti: string[];
        tripType: string;
      };

      if (userType === "driver") {
        if (trip._user.length === 0) {
          state.cancellationReason = "Driver cancels - no seats book";
        } else {
          state.cancellationReason = "Driver cancels - with seats book";

          state.locationName = getCategoryByLocationId(
            trip.destinationLocationId === undefined
              ? trip.pickUpLocationId
              : trip.destinationLocationId
          );

          state.driverName = trip._driver.firstName[0].value;

          state.from =
            trip.destinationLocationId === undefined
              ? getCategoryByLocationId(trip.pickUpLocationId)
              : "Home";

          state.to =
            trip.destinationLocationId === undefined
              ? "Home"
              : getCategoryByLocationId(trip.destinationLocationId);

          state.date = moment(trip.scheduleDate).format(
            `ddd Do MMM, [${trip.destinationLocationId === undefined ? "depart" : "arrive"
            }@]HH:mm`
          );

          state.price = trip._user.map((user) => user.totalPrice);

          state.whatsAppNumber = trip._user.map((user) => user.phone);

          state.jti = trip._user.map((user) => user.jti);

          state.pickUpTime = trip._user.map((userObj) => {
            if (trip.destinationLocationId === undefined) {
              return moment(trip.scheduleDate).format("HH:mm");
            } else {
              return moment(userObj.pickUpTime).format("HH:mm");
            }
          });

          state.dropOffTime = trip._user.map((userObj) => {
            if (trip.destinationLocationId === undefined) {
              return moment(userObj.dropOffTime).format("HH:mm");
            } else {
              return moment(trip.scheduleDate).format("HH:mm");
            }
          });

          state.tripType =
            trip.destinationLocationId === undefined ? "pick-up" : "drop-off";
        }

        await cancelTripStatus(trip._id, state.cancellationReason);

        navigate("/dashboard/cancel-trip", { state });
      } else {
        if (moment() < moment(trip.scheduleDate).subtract(24, "hour")) {
          state.cancellationReason =
            "Passenger cancels - before 24hrs of trip start";

          state.locationName = getCategoryByLocationId(
            trip.destinationLocationId === undefined
              ? trip.pickUpLocationId
              : trip.destinationLocationId
          );

          state.driverName = trip._driver.firstName[0].value;
          state.passengerName = trip._user[0].firstName[0].value;

          state.from =
            trip.destinationLocationId === undefined
              ? getCategoryByLocationId(trip.pickUpLocationId)
              : "Home";

          state.to =
            trip.destinationLocationId === undefined
              ? "Home"
              : getCategoryByLocationId(trip.destinationLocationId);

          state.date = moment(trip.scheduleDate).format(
            `ddd Do MMM, [${trip.destinationLocationId === undefined ? "depart" : "arrive"
            }@]HH:mm`
          );

          // return only currently logged in users's price to send in whatsApp message
          state.price = [
            trip._user.find(
              (userObj) => userObj._id.toString() === user._id.toString()
            ).fuelPrice,
          ];

          state.whatsAppNumber = [trip._driver.phone];

          state.jti = [trip._driver.jti];

          state.pickUpTime =
            trip.destinationLocationId === undefined
              ? [moment(trip.scheduleDate).format("HH:mm")]
              : [
                moment(
                  trip._user.find(
                    (userObj) =>
                      userObj._id.toString() === user._id.toString()
                  )["pickUpTime"]
                ).format("HH:mm"),
              ];

          state.dropOffTime =
            trip.destinationLocationId === undefined
              ? [
                moment(
                  trip._user.find(
                    (userObj) =>
                      userObj._id.toString() === user._id.toString()
                  )["dropOffTime"]
                ).format("HH:mm"),
              ]
              : [moment(trip.scheduleDate).format("HH:mm")];

          state.tripType =
            trip.destinationLocationId === undefined ? "pick-up" : "drop-off";
        } else {
          state.cancellationReason =
            "Passenger cancels - after 24hrs before trip start";

          state.locationName = getCategoryByLocationId(
            trip.destinationLocationId === undefined
              ? trip.pickUpLocationId
              : trip.destinationLocationId
          );

          state.driverName = trip._driver.firstName[0].value;
          state.passengerName = trip._user[0].firstName[0].value;

          state.from =
            trip.destinationLocationId === undefined
              ? getCategoryByLocationId(trip.pickUpLocationId)
              : "Home";

          state.to =
            trip.destinationLocationId === undefined
              ? "Home"
              : getCategoryByLocationId(trip.destinationLocationId);

          state.date = moment(trip.scheduleDate).format(
            `ddd Do MMM, [${trip.destinationLocationId === undefined ? "depart" : "arrive"
            }@]HH:mm`
          );

          // return only currently logged in users's price to send in whatsApp message
          state.price = [
            trip._user.find(
              (userObj) => userObj._id.toString() === user._id.toString()
            ).fuelPrice,
          ];

          state.whatsAppNumber = [trip._driver.phone];

          state.jti = [trip._driver.jti];

          state.pickUpTime =
            trip.destinationLocationId === undefined
              ? [moment(trip.scheduleDate).format("HH:mm")]
              : [
                moment(
                  trip._user.find(
                    (userObj) =>
                      userObj._id.toString() === user._id.toString()
                  )["pickUpTime"]
                ).format("HH:mm"),
              ];

          state.dropOffTime =
            trip.destinationLocationId === undefined
              ? [
                moment(
                  trip._user.find(
                    (userObj) =>
                      userObj._id.toString() === user._id.toString()
                  )["dropOffTime"]
                ).format("HH:mm"),
              ]
              : [moment(trip.scheduleDate).format("HH:mm")];

          state.tripType =
            trip.destinationLocationId === undefined ? "pick-up" : "drop-off";
        }

        await cancelInviteStatus(trip._id, state.cancellationReason);

        navigate("/dashboard/cancel-trip", { state });
      }
    } catch (error) {
      console.log("error: ", error);
      enqueueSnackbar("Unable to cancel trip", { variant: "error" });
    }

    setCancelTripDrawer(false);
    setRedirectLoading(false);
  };

  return (
    <div id="dashboard-trip-detail">
      <Header
        rightIcon={
          <img
            alt="profile"
            className="t-img"
            src={basicDetails.photo ?? DefaultProfile}
          />
        }
      />

      {/* Map Placeholder Here */}
      {trip !== undefined && Object.keys(trip).length > 0 && (trip.tripStatus === "CONFIRMED" || trip.tripStatus === "COMPLETED") && (trip.isBeta !== true) && (
        <div id="google-map-trip-detail" style={{ height: "300px" }}></div>
        // <div style={{width: '100%', height: "15rem"}}>
        //   <img src={trip.mapImageURL} alt="map-route" style={{objectFit: 'cover', width: '100%', height: '100%'}} />
        // </div>
      )}

      {redirectLoading && <LoadingScreen />}

      {/* Trip description in brief below map */}
      {Object.keys(trip).length > 0 && (
        <Box padding="16px" className="bg-white">
          <Box className="seat-info">
            <Box marginBottom="8px" className="sub-text text-faded">
              {moment(trip.scheduleDate).format("ddd Do MMM, HH:mm")}
            </Box>

            <Box marginBottom="8px" className="sub-text text-faded">
              {
                //eslint-disable-next-line
                `${trip.totalSeats}` +
                `${trip._user.length > 1 ? " seats" : " seat"}` +
                `${trip.totalChildSeats
                  ? " (" + trip.totalChildSeats + " child car seat)"
                  : ""
                }`
              }
            </Box>
          </Box>

          <Box marginTop="16px">
            <Box marginBottom="8px" className="sub-text">
              <Box className="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" width="80%">
                  <img alt="circle" src={EmptyCircle} />

                  <p className="sub-text-14 ml-2">
                    {trip.destinationLocationId === undefined
                      ? getCategoryByLocationId(trip.pickUpLocationId)
                      : "Home"}
                  </p>
                </Box>
              </Box>

              <Box
                color="#E0E0E0"
                className="center"
                justifyContent="space-between"
                marginLeft="10px"
              >
                |
              </Box>

              <Box className="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" width="80%">
                  <img alt="circle" src={LocationPin} />

                  <p className="sub-text-14 ml-2">
                    {trip.destinationLocationId === undefined
                      ? "Home"
                      : getCategoryByLocationId(trip.destinationLocationId)}
                  </p>
                </Box>

                <p className="text-700 text-green">
                  {user?._id?.toString() === trip?._driver?._id?.toString()
                    ? tripMeta.totalTime + " mins"
                    : tripMeta.userDuration + " mins"}
                </p>
              </Box>
            </Box>
          </Box>

          <Box marginY={"20px"}>
            <div className="d-flex item-center gap-4">
              <a
                target="_blank"
                style={{ textDecoration: "none" }}
                href={trip.mapUrl}
                rel="noreferrer"
              >
                <div className="d-flex nav-button gap-4">
                  <div className="d-flex">
                    <img src={LocationUpIcon} alt={""} />
                  </div>

                  <div className="d-flex">
                    <span style={{ color: "#171717" }}>Start Route</span>
                  </div>
                </div>
              </a>
            </div>
          </Box>
        </Box>
      )}

      {/* Route template info in the case of driver */}
      {Object.keys(trip).length > 0 && user._id === trip._driver._id && (
        <>
          <div className="section-head"></div>
          {status === "UPCOMING" && (
            <>
              <Box
                className="bg-white cursor-pointer fw-700 d-flex justify-center items-center p-1 border-bottom color-error"
                onClick={() => {
                  setCancelTripDrawer(true);
                  setUserType("driver");
                }}
              >
                Cancel Trip
              </Box>
              <div className="section-head"></div>
            </>
          )}

          <Box
            marginY="24px"
            paddingX="16px"
            className="bg-white carpool-offers"
            style={{ position: "relative" }}
          >
            <div className="carpool-offer-header">
              <p className="heading">Trip details</p>
            </div>

            <Box className="trip-person-info invited-person">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="1rem"
              >
                <Box display="flex" className="items-center">
                  <div style={{ minWidth: "50px" }}>
                    <ImageComponentOverlapping
                      image={[
                        ...trip._user.map((it) => it.photo),
                        ...trip.invitedUsers.map((it) => it.photo),
                      ]}
                    />
                  </div>

                  <Box marginLeft="18px" className="info">
                    <Box display="flex" marginY="4px" className="gap-1">
                      <div className="d-flex">
                        <img alt="car-stearing" src={CarStearingIcon} />
                      </div>

                      <div
                        className={`${getTripColorClass(
                          (trip._user.length > 0 && trip.tripStatus !== "CANCELLED" && trip.tripStatus !== "EXPIRED") ? "CONFIRMED" : trip.tripStatus
                        )} d-flex item-center p-02 px-05 rounded-16 ${trip._user.length === 0 && 'gray-trip-status'}`}
                      >
                        {
                          (trip._user.length > 0 && trip.tripStatus !== "CANCELLED" && trip.tripStatus !== "EXPIRED") && (
                            <div className="mr-2 d-flex">
                              <img
                                alt="car-driver "
                                src={getTripStatusIcon(trip._user.length > 0 ? "CONFIRMED" : trip.tripStatus)}
                              />
                            </div>
                          )
                        }

                        <div className={`text-500 sub-text ${(trip._user.length > 0 && trip.tripStatus !== "CANCELLED" && trip.tripStatus !== "EXPIRED") && 'text-700'}`}>
                          {
                            trip.tripStatus === "CANCELLED" ? (
                              <>
                                Cancelled
                              </>
                            ) : (
                              <>
                              {
                                trip.tripStatus === "EXPIRED" ? (
                                  <>
                                    Expired
                                  </>
                                ) : (
                                  <>
                                    {trip._user.length}/{trip.totalSeats} seats booked
                                  </>
                                )
                              }
                              </>
                            )
                          }
                        </div>
                      </div>
                    </Box>

                    <p className="sub-text text-faded text-500">
                      {moment(trip.scheduleDate).format(`Do MMM, [${trip.destinationLocationId === undefined ? "depart" : "arrive"
            }@]HH:mm`)}
                    </p>
                  </Box>
                </Box>
              </Box>

              <Box marginBottom="8px" className="sub-text">
                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img alt="circle" src={Leaf} />

                    <p className="sub-text-14 ml-2">
                      {trip.destinationLocationId === undefined
                        ? getCategoryByLocationId(trip.pickUpLocationId)
                        : "Home"}
                    </p>
                  </Box>

                  <p className="sub-text text-700 text-faded  min-145 text-center">
                    {moment(trip.tripStartTime).format("HH:mm")}
                  </p>
                </Box>

                <Box
                  color="#E0E0E0"
                  className="center"
                  justifyContent="space-between"
                  marginLeft="10px"
                >
                  |
                </Box>

                {trip._user.map((user, index) => (
                  <Box key={index}>
                    <Box className="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <img
                          alt="circle"
                          className="rounded-full fit-img-sm  bordered-2 shadow-light"
                          style={{ borderRadius: "50%" }}
                          width={"20px"}
                          src={user.photo ?? DefaultProfile}
                        />

                        <p className="sub-text-14 ml-2">
                          {capitalizeFirstLetter(getName(user.firstName, "EN"))}{" "}
                          {capitalizeFirstLetter(getName(user.lastName, "EN"))}
                        </p>
                      </Box>

                      <p className="sub-text text-700 text-faded min-130 text-center">
                        <span style={{ fontWeight: "400" }}>ETA </span>

                        <span>
                          {" "}
                          {trip.destinationLocationId === undefined
                            ? moment(user.dropOffTime).format("HH:mm")
                            : moment(user.pickUpTime).format("HH:mm")}{" "}
                        </span>

                        <span style={{ fontWeight: "400" }}>
                          {" "}
                          £{user.fuelPrice.toFixed(2)}{" "}
                        </span>
                      </p>
                    </Box>

                    <Box
                      color="#E0E0E0"
                      className="center"
                      justifyContent="space-between"
                      marginLeft="10px"
                    >
                      |
                    </Box>
                  </Box>
                ))}

                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img alt="circle" src={LocationPin} />

                    <p className="sub-text-14 ml-2">
                      {trip.destinationLocationId === undefined
                        ? "Home"
                        : getCategoryByLocationId(trip?.destinationLocationId)}
                    </p>
                  </Box>
                  <p className="sub-text primary2-color text-600 min-145 text-center">
                    {moment(trip.tripEndTime).format("HH:mm")}
                  </p>
                </Box>
              </Box>

              <Box
                className="center bg-f5 p-05 rounded-2"
                marginTop={"20px"}
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <p className="sub-text-14 text-700 ml-2">{"Price"}</p>
                </Box>
                <p className="sub-text text-right text-700 min-20">
                  {`£${getTotalTripPrice()}`}
                </p>
              </Box>
            </Box>
          </Box>

          <Box
            marginY="24px"
            paddingX="16px"
            className="bg-white carpool-offers"
            style={{ position: "relative" }}
          >
            <div className="carpool-offer-header">
              <p className="heading">Passengers</p>
            </div>
          </Box>

          <div style={{ margin: "0px 16px" }}>
            {trip._user.length > 0 && (
              <p className="section-head green">Confirmed</p>
            )}

            {trip._user.map((user, index) => (
              <Fragment key={index}>
                <Box display="flex" className="click margin-y">
                  <div className="confirmed-passenger">
                    <img
                      alt="profile"
                      className="image-thumbnail fit-img rounded-full bordered-2 shadow-light cursor-pointer"
                      src={user.photo}
                      onClick={() => setUserDetail({ showUserDetail: true, userDetail: user, locationName: getOrgNameByLocationId(trip?.destinationLocationId === undefined ? trip?.pickUpLocationId : trip?.destinationLocationId, {userDetail: user}), organisationLogo: getOrgLogoByLocationId(trip?.destinationLocationId === undefined ? trip?.pickUpLocationId : trip?.destinationLocationId, {userDetail: user}) })}
                    />
                  </div>

                  <Box marginLeft={"16px"} className="info">
                    <p className="sub-text-14 driver-name d-flex items-center gap-4">
                      {capitalizeFirstLetter(getName(user.firstName, "EN"))}{" "}
                      {capitalizeFirstLetter(getName(user.lastName, "EN"))}

                      {/* <img
                        src={user.isVerified ? userVerified : userNotVerified}
                        alt=""
                      /> */}

                      {
                        user.isDriverVerified && (
                        <img
                          className="verified-user"
                          src={CarStearingIcon}
                          alt="driver verified"
                        />
                      )
                    }
                    </p>

                    <div className="d-flex">
                      <div
                        className={`${getTripColorClass(
                          "COMPLETED"
                        )} d-flex item-center p-02 px-05 my-1 rounded-16`}
                      >
                        <div className="mx-2 d-flex">
                          <img
                            alt="car-driver "
                            src={getTripStatusIcon("COMPLETED")}
                          />
                        </div>

                        <div className="sub-text text-700">{"Confirmed"}</div>
                      </div>
                    </div>

                    <p className="sub-text text-500 mb-1 grey-color">
                      {trip.destinationLocationId === undefined ? (
                        <>
                          Est dropoff time{" "}
                          {moment(user.dropOffTime).format("HH:mm")}
                        </>
                      ) : (
                        <>
                          Est pickup time{" "}
                          {moment(user.pickUpTime).format("HH:mm")}
                        </>
                      )}
                    </p>
                  </Box>
                </Box>
              </Fragment>
            ))}
          </div>

          <div style={{ margin: "0px 16px" }}>
            {trip.invitedUsers.length > 0 && (
              <p className="section-head green">Invited</p>
            )}

            {trip.invitedUsers.map((user) => (
              <>
                <Box display="flex" className="click margin-y">
                  <div className="confirmed-passenger">
                    <img
                      alt="profile"
                      className="image-thumbnail fit-img rounded-full bordered-2 shadow-light cursor-pointer"
                      src={user.photo}
                      onClick={() => setUserDetail({ showUserDetail: true, userDetail: user, locationName: getOrgNameByLocationId(trip?.destinationLocationId === undefined ? trip?.pickUpLocationId : trip?.destinationLocationId, {userDetail: user}), organisationLogo: getOrgLogoByLocationId(trip?.destinationLocationId === undefined ? trip?.pickUpLocationId : trip?.destinationLocationId, {userDetail: user}) })}
                    />
                  </div>

                  <Box marginLeft={"16px"} className="info">
                    <p className="sub-text-14 driver-name d-flex gap-4">
                      {capitalizeFirstLetter(getName(user.firstName, "EN"))}{" "}
                      {capitalizeFirstLetter(getName(user.lastName, "EN"))}
                      
                      {/* <img
                        src={user.isVerified ? userVerified : userNotVerified}
                        alt=""
                      /> */}

                      {
                        user.isDriverVerified && (
                        <img
                          className="verified-user"
                          src={CarStearingIcon}
                          alt="driver verified"
                        />
                      )
                    }
                    </p>

                    <div className="d-flex">
                      <div
                        className={`yellow d-flex item-center p-02 px-05 my-1 rounded-16`}
                      >
                        <div className="mx-2 d-flex">
                          <img
                            alt="car-driver "
                            src={getTripStatusIcon("PENDING")}
                          />
                        </div>
                        <div className="sub-text text-700">{"Pending"}</div>
                      </div>
                    </div>

                    <p className="sub-text text-500 mb-1 grey-color">
                      {trip.destinationLocationId === undefined ? (
                        <>
                          Est dropoff time{" "}
                          {moment(user.dropOffTime).format("HH:mm")}
                        </>
                      ) : (
                        <>
                          Est pickup time{" "}
                          {moment(user.pickUpTime).format("HH:mm")}
                        </>
                      )}
                    </p>
                  </Box>
                </Box>
              </>
            ))}
          </div>
        </>
      )}

      {/* Route template info in the case of passenger */}
      {Object.keys(trip).length > 0 && user._id !== trip._driver._id && (
        <>
          <div className="section-head"></div>
          {status === "UPCOMING" && (
            <>
              <Box
                className="bg-white cursor-pointer fw-700 d-flex justify-center items-center p-1 border-bottom color-error"
                onClick={() => {
                  setCancelTripDrawer(true);
                  setUserType("passenger");
                }}
              >
                Cancel Trip
              </Box>
              <div className="section-head"></div>
            </>
          )}

          <Box
            marginY="24px"
            paddingX="16px"
            className="bg-white carpool-offers"
            style={{ position: "relative" }}
          >
            <div className="carpool-offer-header">
              <p className="heading">Trip details</p>
            </div>

            <Box className="trip-person-info invited-person">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="1rem"
              >
                <Box display="flex" className="items-center">
                  <div>
                    <img
                      alt="profile"
                      src={
                        trip._driver.photo
                          ? trip._driver.photo
                          : DefaultProfile
                      }
                      height="44px"
                      width="44px"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DefaultProfile;
                      }}
                      className="rounded-full bordered-2 shadow-light object-fit-cover cursor-pointer"
                      onClick={() => setUserDetail({ showUserDetail: true, userDetail: trip._driver, locationName: getOrgNameByLocationId(trip?.destinationLocationId === undefined ? trip?.pickUpLocationId : trip?.destinationLocationId, {userDetail: trip._driver}), organisationLogo: getOrgLogoByLocationId(trip?.destinationLocationId === undefined ? trip?.pickUpLocationId : trip?.destinationLocationId, {userDetail: trip._driver}) })}
                    />
                  </div>

                  <Box marginLeft="18px" className="info">
                    <p className="sub-text-14 driver-name d-flex gap-4">
                      {capitalizeFirstLetter(
                        getName(trip._driver.firstName, "EN")
                      )}{" "}

                      {capitalizeFirstLetter(
                        getName(trip._driver.lastName, "EN")
                      )}
                    </p>

                    <Box display="flex" marginY="4px" className="gap-05">
                      <div className="d-flex">
                        <img alt="car-stearing" src={CarDrivingIcon} />
                      </div>

                      <div
                        className={`${trip.tripStatus === "CANCELLED" ? 'red-trip-status' : 'bg-green'} d-flex item-center p-02 px-05 rounded-16`}
                      >
                        {
                          trip.tripStatus !== "CANCELLED" && (
                            <div className="mr-2 d-flex">
                              <img
                                alt="car-driver "
                                src={getTripStatusIcon("CONFIRMED")}
                              />
                            </div>
                          )
                        }

                        <div className={`text-md ${trip.tripStatus !== "CANCELLED" && 'text-700'}`}>
                            {
                              trip.tripStatus === "CANCELLED" ? (
                                <>
                                  Cancelled
                                </>
                              ) : (
                                <>
                                  Accepted
                                </>
                              )
                            }
                        </div>
                      </div>
                    </Box>

                    <p className="sub-text text-faded">
                      {moment(trip.scheduleDate).format(`Do MMM, [${trip.destinationLocationId === undefined ? "depart" : "arrive"
            }@]HH:mm`)}
                    </p>
                  </Box>
                </Box>
              </Box>

              <Box marginBottom="8px" className="sub-text">
                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img alt="circle" src={Leaf} />

                    <p className="sub-text-14 ml-2">
                      {trip.destinationLocationId === undefined
                        ? getCategoryByLocationId(trip.pickUpLocationId)
                        : "Home"}
                    </p>
                  </Box>

                  <p className="sub-text text-700 text-faded  min-130 text-right">
                    {trip.destinationLocationId !== undefined && (
                      <span style={{ fontWeight: "400" }}>ETA </span>
                    )}

                    {trip.destinationLocationId === undefined ? (
                      <>{moment(trip.tripStartTime).format("HH:mm")}</>
                    ) : (
                      <>
                        {moment(
                          trip._user.find(
                            (userObj) =>
                              userObj._id.toString() === user._id.toString()
                          ).pickUpTime
                        ).format("HH:mm")}
                      </>
                    )}
                  </p>
                </Box>

                <Box
                  color="#E0E0E0"
                  className="center"
                  justifyContent="space-between"
                  marginLeft="10px"
                >
                  |
                </Box>

                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img alt="circle" src={LocationPin} />

                    <p className="sub-text-14 ml-2">
                      {trip.destinationLocationId === undefined
                        ? "Home"
                        : getCategoryByLocationId(trip?.destinationLocationId)}
                    </p>
                  </Box>

                  <p className="sub-text primary2-color text-600 min-145 text-right">
                    {trip.destinationLocationId === undefined && (
                      <span style={{ fontWeight: "400" }}>ETA </span>
                    )}

                    {trip.destinationLocationId === undefined ? (
                      <>
                        {moment(
                          trip._user.find(
                            (userObj) =>
                              userObj._id.toString() === user._id.toString()
                          ).dropOffTime
                        ).format("HH:mm")}
                      </>
                    ) : (
                      <>{moment(trip.tripEndTime).format("HH:mm")}</>
                    )}
                  </p>
                </Box>
              </Box>

              <Box
                className="center bg-f5 p-05 rounded-2"
                marginTop={"20px"}
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <p className="sub-text-14 text-700 ml-2">{"Price"}</p>
                </Box>
                <p className="sub-text text-right text-700 min-20">
                  {`£${getTotalTripPriceForUser()}`}
                </p>
              </Box>
            </Box>

            <Box className="trip-details-receipt-icon" onClick={async () => {
              setRedirectLoading(true);
              try {
                const link = (await getTripReceipt(trip._id) as {path: string}).path;
                // let url = '';
                // fetch(link)
                //   .then(response => response.blob())
                //   .then(blob => {
                //     url = URL.createObjectURL(blob);
                //     const a = document.createElement('a');
                //     a.href=url;
                //     a.download="trip_receipt.pdf";
                //     document.body.appendChild(a);
                //     a.click();
                //     document.body.removeChild(a);
                //   })
                //   .catch(err => {
                //     enqueueSnackbar(err.message || 'Error occurred while downloading pdf', {
                //       variant: 'error'
                //     });
                //   })
                //   .finally(() => {
                //     URL.revokeObjectURL(url);
                //   })

                const a = document.createElement('a');
                a.href=link;
                a.download="trip_receipt.pdf";
                document.body.appendChild(a);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
              } catch (error) {
                enqueueSnackbar('Error occurred while generating receipt', { variant: 'error' });
              } finally {
                setRedirectLoading(false);
              }
            }}>
              {/* <div> */}
                <img src={receiptIcon} alt="receipt" />
              {/* </div> */}
              <b>
                &nbsp;Download receipt
              </b>
            </Box>
          </Box>
        </>
      )}

      <CustomDrawer
        anchor="bottom"
        isOpen={cancelTripDrawer}
        onClose={() => setCancelTripDrawer(false)}
      >
        <div className="d-flex justify-center items-center p-1 heading">
          Cancel Trip
        </div>

        <div className="warning-message p-1 text-center text-sm-2">
          <p>Are you sure that you would like to cancel this trip?</p>
        </div>

        <div
          className="cancel-button cursor-pointer d-flex justify-center items-center fw-700"
          style={{ color: "#3D53F5" }}
          onClick={() => setCancelTripDrawer(false)}
        >
          <p>Cancel</p>
        </div>

        <Box className="d-flex justify-center items-center p-1">
          <Button
            className="next-btn custom-btn btn-width px-15rem text-color-white cancel-btn-background"
            fullWidth
            onClick={cancelButtonHandler}
          >
            Confirm
          </Button>
        </Box>
      </CustomDrawer>

      <UserProfile 
        isOpen={userDetail.showUserDetail}
        onClose={() => setUserDetail(prev => ({...prev, showUserDetail: false}))}
        userDetail={{...userDetail.userDetail, locationName: userDetail.locationName, organisationLogo: userDetail.organisationLogo}}
      />
    </div>
  );
};

export default TripDetail;
