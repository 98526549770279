import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getDraftFavouriteLocations, getDraftVehicleDetails } from '../../services/car-detail'
import { AxiosError } from 'axios'
import { updateDraftFavouriteLocation } from '../../services/location'
import { getDraftHomeDetails } from '../../services/signup'

export interface multiLanguage {
    lang: string,
    value: string
}

export interface user {
    email: string,
    _id: string,
}

export interface BasicDetails {
    firstName: string
    lastName: string
    email: string
    gender: string
    phoneNumber: string
    profilePicture: File | string
    photoPath: string
    carPoolPreference: string
    isVerified?: boolean,
    occupation?: string
}

export interface VehicleDetails {
    carRegistrationNumber: string
    numOfSeatsForChild: number
    numOfSeatsAvailableForCarPoolPassengers: number,
    isMOT ?: boolean,
    isDrivingLicence ?: boolean,
    isInsurance ?: boolean,
}

export interface Confirmation {
    validLicense: boolean
    validMOT: boolean
    activeInsurance: boolean
}

export interface HomeLocation {
    state: string,
    city: string,
    country?: string,
    line1: string,
    line2?: string,
    geo?: number[],
    zip: string,
    additionalInstructions?: string
}

export interface DailyScheduleInterface {
    dropOffFlexibility: string
    pickUpFlexibility: string
    schedule: Array<ScheduleInterface>
}

export interface ScheduleInterface {
    day: string
    dropOff: string
    pickUp: string
}

export interface FavLocationInterface {
    categoryName: string
    locationName: string
    categoryId: string
    locationId: string
    dailySchedule: DailyScheduleInterface
}

export interface SignUpDetails {
    user: user
    basicDetails: BasicDetails
    confirmation: Confirmation
    homeLocation: HomeLocation
    vehicleDetails: VehicleDetails
    favLocation: Array<FavLocationInterface>
    vehicleLoading: boolean,
    vehicleError: boolean,
    favouriteLocationLoading: boolean,
    favouriteLocationError: boolean,
    homeLocationError: boolean,
}

// get vehicle details for the draft user
export const getDraftUserVehicleDetail = createAsyncThunk('signUpDetails/getVehicleDetails', async (draftId: string, thunkAPI) => {
    try {
        const vehicleResponse = await getDraftVehicleDetails(draftId);

        return vehicleResponse;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError)
    }
})

// get home location details for the draft user
export const getDraftUserHomeDetail = createAsyncThunk('signUpDetails/getHomeDetails', async (draftId: string, thunkAPI) => {
    try {
        const homeLocationResponse = await getDraftHomeDetails(draftId);

        return homeLocationResponse;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError)
    }
})

// get favourite location details for the draft user
export const getDraftUserFavouriteLocation = createAsyncThunk('signUpDetails/getFavouriteLocations', async (draftId: string, thunkAPI) => {
    try {
        const locationResponse = await getDraftFavouriteLocations(draftId);

        return locationResponse;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError)
    }
})

// update favourite location details for the draft user
export const updateDraftUserFavouriteLocation = createAsyncThunk('signUpDetails/updateFavouriteLocations', async (favouriteLocations: object, thunkAPI) => {
    try {
        const locationResponse = await updateDraftFavouriteLocation(favouriteLocations);

        return locationResponse;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError)
    }
})

const initialState: SignUpDetails = {
    user: {
        email: "",
        _id: ""
    },
    basicDetails: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "MALE",
        phoneNumber: "",
        profilePicture: "",
        carPoolPreference: "OFFER",
        photoPath: "",
        occupation: ""
    },
    confirmation: {
        validLicense: false,
        validMOT: false,
        activeInsurance: false
    },
    homeLocation: {
        state: "",
        city: "",
        line1: "",
        line2: "",
        country: "NA",
        zip: "",
        geo: [0, 0],
        additionalInstructions: ""
    },
    vehicleDetails: {
        carRegistrationNumber: "",
        numOfSeatsAvailableForCarPoolPassengers: 0,
        numOfSeatsForChild: 0,
        isMOT: false,
        isDrivingLicence:false,
        isInsurance:false
    },
    favLocation: [] as FavLocationInterface[],
    vehicleLoading: false,
    vehicleError: false,
    favouriteLocationLoading: false,
    favouriteLocationError: false,
    homeLocationError: false
}

export const signUpDetailsSlice = createSlice({
    name: 'signUpDetailsReducer',
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<user>) => {
            state.user = action.payload
        },
        updateBasicDetails: (state, action: PayloadAction<BasicDetails>) => {
            state.basicDetails = action.payload
        },
        updateVehicleDetails: (state, action: PayloadAction<VehicleDetails>) => {
            state.vehicleDetails = action.payload
        },
        updateHomeLocation: (state, action: PayloadAction<HomeLocation>) => {
            state.homeLocation = action.payload
        },
        updateConfirmation: (state, action: PayloadAction<Confirmation>) => {
            state.confirmation = action.payload
        },
        updateFavLocation: (state, action: PayloadAction<FavLocationInterface[]>) => {
            state.favLocation = action.payload
        },
        resetSignUpDetailsSlice: (state) => {
            state.basicDetails = initialState.basicDetails
            state.confirmation = initialState.confirmation
            state.homeLocation = initialState.homeLocation
            state.vehicleDetails = initialState.vehicleDetails
            state.user = initialState.user
            state.favLocation = initialState.favLocation
        },
        resetVehicleError: state => {
            state.vehicleError = false
        },
        resetFavouriteLocationError: state => {
            state.favouriteLocationError = false
        },
        resetHomeLocationError: state => {
            state.homeLocationError = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDraftUserVehicleDetail.pending, (state) => {
            state.vehicleLoading = true
        }).addCase(getDraftUserVehicleDetail.fulfilled, (state, action) => {
            console.log('vehicle details: ', action.payload)
            state.vehicleDetails.carRegistrationNumber = action.payload.registrationNo
            state.vehicleDetails.numOfSeatsAvailableForCarPoolPassengers = action.payload.noOfSeatAvailable
            state.vehicleDetails.numOfSeatsForChild = action.payload.noOfChildSeatAvailable
            state.vehicleLoading = false
        }).addCase(getDraftUserVehicleDetail.rejected, (state, action) => {
            state.vehicleLoading = false

            // show the error on UI if the user has previously set his/her vehicle details
            if ((action.payload as { response: { data: { meta: { vehicleDetailsExists: boolean } } } }).response.data.meta.vehicleDetailsExists) {
                state.vehicleError = true
            }
        }).addCase(getDraftUserFavouriteLocation.pending, (state) => {
            state.favouriteLocationLoading = true
        }).addCase(getDraftUserFavouriteLocation.fulfilled, (state, action) => {
            state.favLocation = action.payload
            state.favouriteLocationLoading = false
        }).addCase(getDraftUserFavouriteLocation.rejected, (state, action) => {
            state.favouriteLocationLoading = false
            state.favouriteLocationError = true
        }).addCase(getDraftUserHomeDetail.fulfilled, (state, action) => {
            const homeLocationPayload = {
                state: action.payload?.address?.state[0].value ?? '',
                city: action.payload?.address?.city[0].value ?? '',
                // if signup for beta-location, then the address field will be undefined, therefore we set it to 'undefined' so that infinite requests are not made on signup summary page
                line1: action.payload?.address?.line1[0]?.value ?? 'undefined',
                line2: action.payload?.address?.line2[0]?.value ?? '',
                country: action.payload?.address?.country[0]?.value ?? '',
                zip: action.payload?.address?.zip,
                geo: action.payload?.address?.geo,
                additionalInstructions: action.payload?.address?.additionalInstructions[0]?.value ?? ''
            }
            state.homeLocation = homeLocationPayload
        }).addCase(getDraftUserHomeDetail.rejected, (state, action) => {
            state.homeLocationError = true
        })
    }
})

// Action creators are generated for each case reducer function
export const {
    updateUser,
    updateBasicDetails,
    updateConfirmation,
    updateHomeLocation,
    updateVehicleDetails,
    resetSignUpDetailsSlice,
    updateFavLocation,
    resetVehicleError,
    resetFavouriteLocationError,
    resetHomeLocationError
} = signUpDetailsSlice.actions

export default signUpDetailsSlice.reducer

