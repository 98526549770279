import { useState } from "react";
import "./style.scss";
import {
  logoWithName as Logo,
  defaultProfile as Profile,
  person as Person,
  car as Car,
  logout,
  hamBurgerMenu as HamburgerMenu,
  // bell as Bell,
  // leafIcon as Leaf,
  shoppingBagLg as ShoppingBag,
  routeIcon as Route,
  groupIcon,
} from "../../assets/images";
import NotebookIcon from "../../assets/images/Notebook.svg"
import HelpIcon from "../../assets/images/Help.svg"
import LockIcon from "../../assets/images/Lock.svg"
import Calendar from "../../assets/images/calendar.svg"
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { logOutUser } from "../../services/logout";
import { resetUserState } from "../../slice/login";
import { enqueueSnackbar } from "notistack";
import { FC } from "react";
import MarketPlaceIvite from '../../assets/images/marketplace_friends.svg'


interface ILoginProp {
  login?: boolean;
  rightIcon?: JSX.Element;
}

const WebHeader: FC<ILoginProp> = ({ login = false }) => {
  const state = useSelector((state: RootState) => state);
  const user = state.userDetailsReducer.basicDetails;
  const { signUpDetails } = state;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const navigateRoute = (url: string) => {
    navigate(url);
    closeMenu();
  };

  const handleLogOut = async () => {
    try {
      await logOutUser();
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem("token");
      enqueueSnackbar("Logout Successfully");
      closeMenu();
      dispatch(resetUserState());
      navigate("/");
    }
  };

  const handleButtonClick = () => {
    pathname === "/" ?
    // navigate("/signup")
    window.location.href = "https://www.ecopooling.co.uk/signup"
     : navigate("/");
  };

  return (
    <div
      id="web-header"
      // style={{
      //   margin: pathname === "/dashboard/home" ? "0" : "0 0 2.5rem",
      // }}
    >
      {user.email?.length > 0 ? (
        <div className={"header-box-with-back parent"}>
          <img
            onClick={() => user.userPreference === 'MARKET_PLACE' ? navigate("/dashboard/market-place") :
              navigate("/dashboard/home")}
            alt="logo"
            src={Logo}
            className="cursor-pointer"
          />
          {
          <div className="d-flex items-center gap-05">
            {/* <IconButton className='icon-btn' disabled={user.email.length ? false : true}> */}
            {user.userPreference !== 'MARKET_PLACE' && (<Button
              variant="outlined"
              className="my-upcoming-trips-button"
              onClick={() => navigate("/dashboard/upcoming")}
            >
              My upcoming trips
            </Button>)}
            <img
              alt="profile"
              className="header-profile-image bordered-2 shadow-light "
              src={user?.photo?.length ? user?.photo : Profile}
            />
            {/* </IconButton> */}
            <img
              className="cursor-pointer"
              src={HamburgerMenu}
              alt="hamburger-menu"
              onClick={handleClick}
            />
          </div>
          }
        </div>
      ) : (
        <div className={"header-box-with-back parent"}>
          <img
            onClick={() => user.userPreference === 'MARKET_PLACE' ? navigate("/dashboard/market-place") :
              navigate("/")}
            alt="logo"
            src={Logo}
            className="cursor-pointer"
          />

          {!login && (
            <IconButton className="icon-btn">
              {signUpDetails.basicDetails.photoPath && (
                <img
                  alt="profile"
                  className="header-profile-image bordered-2 shadow-light"
                  src={signUpDetails.basicDetails.photoPath}
                />
              )}
              {!signUpDetails.basicDetails.photoPath && (
                <img
                  alt="profile"
                  className="header-profile-image bordered-2 shadow-light"
                  src={Profile}
                />
              )}
            </IconButton>
          )}
          {(login || pathname === "/" || pathname === "/signup") && user.userPreference !== 'MARKET_PLACE' && (
            <Button
              variant="outlined"
              className="login-signup-button"
              onClick={handleButtonClick}
              style={{ borderWidth: '2px' }}
            >
              {pathname === "/" ? "Sign up" : "Log in"}
            </Button>
          )}
          { user.userPreference === 'MARKET_PLACE' && localStorage.getItem("token") &&
            <img
            className="cursor-pointer"
            src={HamburgerMenu}
            alt="hamburger-menu"
            onClick={handleClick}
          />
          }
        </div>
      )}

      {/*DEPRECATED DUE TO PHASE 4 REQUIREMENT */}
      {/* {user.email !== "" && (
          <div className="sub-header">
            <Box display="flex">
              <p
                onClick={() => navigateRoute("dashboard/upcoming")}
                className="link cursor-pointer"
              >
                Upcoming Trips
              </p>
              <p
                onClick={() => navigateRoute("dashboard/schedule-detail")}
                className="link cursor-pointer"
              >
                My Invite Alerts
              </p>
              <p
                onClick={() => navigateRoute("dashboard/eco-saving")}
                className="link cursor-pointer"
              >
                My Eco Savings
              </p>
              <p
                onClick={() => navigateRoute("dashboard/market-place")}
                className="link cursor-pointer"
              >
                Rewards Marketplace
              </p>
            </Box>
          </div>
        )} */}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigateRoute("dashboard/profile")}>
          <ListItemIcon>
            <img width="17px" alt="profile" src={Person} />
          </ListItemIcon>
          <p className="">My profile</p>
        </MenuItem>
        {user.userPreference !== "FINDER" && user.userPreference !== "MARKET_PLACE" && (
          <MenuItem onClick={() => navigateRoute("dashboard/vehicle-detail")}>
            <ListItemIcon>
              <img width="17px" alt="my-vehicle" src={Car} />
            </ListItemIcon>
            My vehicle
          </MenuItem>
        )}
        {user.userPreference !== "MARKET_PLACE" && <MenuItem onClick={() => navigateRoute("dashboard/upcoming")}>
          <ListItemIcon>
            <img width="17px" alt="route-icon" src={Route} />
          </ListItemIcon>
          <p className="">My trips</p>
        </MenuItem>}
        {user.userPreference !== "MARKET_PLACE" && 
          <MenuItem onClick={() => navigateRoute("dashboard/schedule")}>
          <ListItemIcon>
            <img width="17px" alt="route-icon" src={Calendar} />
          </ListItemIcon>
          <p className="">My schedule</p>
        </MenuItem>}
        {/* {user.userPreference !== "OFFER" && user.userPreference !== "MARKET_PLACE" &&
        <MenuItem onClick={() => navigateRoute("dashboard/invite-alerts")}>
          <ListItemIcon>
            <img width="17px" alt="route-icon" src={bell} />
          </ListItemIcon>
          <p className="">My invite alerts</p>
        </MenuItem>} */}
        {user.userPreference !== "FINDER" && user.userPreference !== "MARKET_PLACE" &&
          (<MenuItem onClick={() => navigateRoute("dashboard/group")}>
          <ListItemIcon>
            <img width="17px" alt="route-icon" src={groupIcon} />
          </ListItemIcon>
          <p className="">My carpool group</p>
        </MenuItem>)}
        {/* <MenuItem onClick={() => navigateRoute("dashboard/schedule-detail")}>
          <ListItemIcon>
            <img width="17px" alt="bell-icon" src={Bell} />
          </ListItemIcon>
          <p className="">My invite alerts</p>
        </MenuItem> */}
        {
        user.userPreference !== "MARKET_PLACE" && <MenuItem onClick={() => navigateRoute("dashboard/market-place/invite")}>
          <ListItemIcon>
            <img width="17px" alt="add_user" src={MarketPlaceIvite} />
          </ListItemIcon>
          <p className="">Invite friends to marketplace</p>
        </MenuItem>
        }
        <MenuItem onClick={() => navigateRoute("dashboard/market-place")}>
          <ListItemIcon>
            <img width="17px" alt="shopping-bag" src={ShoppingBag} />
          </ListItemIcon>
          <p className="">Rewards marketplace</p>
        </MenuItem>
        {/* <MenuItem onClick={() => navigateRoute("dashboard/eco-saving")}>
          <ListItemIcon>
            <img width="17px" alt="leaf-icon" src={Leaf} />
          </ListItemIcon>
          <p className="">My eco savings</p>
        </MenuItem> */}

        <MenuItem onClick={() => window.open(`${process.env.REACT_APP_HELP_URL}`, "_blank")}>
          <ListItemIcon>
            <img width="17px" alt="leaf-icon" src={HelpIcon} />
          </ListItemIcon>
          <p className="">Help center</p>
        </MenuItem>

        <MenuItem onClick={() => window.open("https://drive.google.com/file/d/1H_ktE1jW7c-ztjrFqOlOarF10mlSoDdV/view", "_blank")}>
          <ListItemIcon>
            <img width="17px" alt="leaf-icon" src={NotebookIcon} />
          </ListItemIcon>
          <p className="">Terms and conditions</p>
        </MenuItem>

        <MenuItem onClick={() => window.open("https://drive.google.com/file/d/1ZsP8MWMiqhvKyPQ-lhqmY_VFbZI5Q-tu/view?usp=sharing", "_blank")}>
          <ListItemIcon>
            <img width="17px" alt="leaf-icon" src={LockIcon} />
          </ListItemIcon>
          <p className="">Privacy policy</p>
        </MenuItem>

        <MenuItem onClick={() => handleLogOut()}>
          <ListItemIcon>
            <img width="17px" alt="logout" src={logout} />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>
    </div>
  );
};

WebHeader.defaultProps = {
  rightIcon: <Button variant="outlined">Sign up</Button>,
};

export default WebHeader;
